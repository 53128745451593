import React from 'react';

// Assets
import summitChallenge from '../assets/portfolio/summitChallenge.JPG';
import creationTaraxacum from '../assets/portfolio/creationTaraxacum.JPG';
import weatherapp from '../assets/portfolio/weatherapp.JPG';

const Portfolio = () => {

   const portfolios = [
    {
        id: 1,
        src: creationTaraxacum,
        name: "Création Taraxacum",
        description: "Création du site web de l'entreprise de paysagement Création Taraxacum ainsi qu'une révision de logo et la configuration des médias sociaux de l'entreprise",
        link: 'https://taraxacum.ca',
    },
    {
        id: 2,
        src: weatherapp,
        name: "Application météo",
        description: "Projet d'une application météo en utilisant react et tailwind à l'aide de l'api openweathermap",
        link: 'https://weather-app-simonstmichel.netlify.app/',
    },
    {
        id: 3,
        src: summitChallenge,
        name: "Summit Challenge 2021",
        description: "Pas relié au web, mais ce projet est spécial pour moi car Sébastien mathys et moi nous sommes donné corps et âme pour le réaliser.",
        link: 'https://www.youtube.com/watch?v=4JrCK1hmJDM',
    },
   ];

  return (
    <div name="Portfolio" className='bg-gradient-to-b from-black to-blue-800 w-full text-white md:h-screen'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-cyan-500'>Portfolio</p>
                <p className='py-6'>Mes projets</p>
            </div>
            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0'>
                {portfolios.map(({id, src, name, description, link}) => 
                <div key={id} className='shadow-md shadow-blue-400 rounded-lg bg-black/60'>
                    <a href={link} target="_blank"><img src={src} alt={name} className='rounded-md duration-200 hover:scale-105 w-full h-1/2 object-cover cursor-pointer' /></a>
                   
                    <div className='flex items-center justify-center flex-col'>
                        {/* <button className='w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105'>Visiter</button>
                        <button className='w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105'>Code</button> */}
                        <p className='pt-4 font-bold'>{name}</p>
                        <p className='p-4 text-justify text-sm'>{description}</p>
                    </div>
                </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Portfolio;