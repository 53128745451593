import React, { useState } from 'react';
import { Link } from 'react-scroll';

// Icons
import {FaBars, FaTimes} from 'react-icons/fa';

const NavBar = () => {

    const [nav, setNav] = useState(false);

    const links = [
        {
            id: 1,
            url: 'À propos',
        },
        {
            id: 2,
            url: 'Portfolio',
        },
        {
            id: 3,
            url: 'Expérience',
        },
        {
            id: 4,
            url: 'Contact',
        },
    ]

  return (
    <div className='flex justify-between items-center w-full h-20 px-2 text-white bg-black fixed z-10 lg:px-12'>
        <div>
            <h1 className='text-2xl ml-2 truncate font-spacemono hover:cursor-pointer md:text-3xl lg:text-4xl'><Link to="Home" smooth duration={500}>Simon St-Michel</Link></h1>
        </div>
        <ul className='hidden md:flex'>
            {links.map(({id, url}) =>
                <li key={id} className='px-4 cursor-pointer capitalize font-medium text-gray-500 hover:scale-105 hover:font-bold hover:bg-gradient-to-r from-cyan-500 to-blue-500 duration-200 hover:text-transparent hover:bg-clip-text'><Link to={url} smooth duration={500}>{url}</Link></li>
                )}
        </ul>
        <div onClick={() => setNav(current => current = !current)} className='cursor-pointer pr-4 z-10 text-gray-500 md:hidden'>
            {nav ? <FaTimes size={30}/> : <FaBars size={30}/>}
            
        </div>

        {nav && (
                    <ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-gray-500'>
                    {links.map(({id, url}) =>
                        <li key={id} className='px-4 cursor-pointer capitalize font-medium text-gray-500 hover:scale-105'><Link to={url} smooth duration={500} className="hover:bg-gradient-to-r from-cyan-500 to-blue-500 duration-200 hover:text-transparent hover:bg-clip-text">{url}</Link></li>
                        )}
                </ul>
        )}

    </div>
  )
}

export default NavBar;