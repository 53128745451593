import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

// Icons
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

// Assets
import photo1 from '../assets/HeroImages/Photo1.png';
import photo2 from '../assets/HeroImages/Photo2.png';
import photo3 from '../assets/HeroImages/Photo3.png';
import photo4 from '../assets/HeroImages/Photo4.png';
import photo5 from '../assets/HeroImages/Photo5.png';

const Home = () => {

  const [activeIndex, setActiveIndex] = useState(0);

  const photos = [photo2, photo1, photo3, photo4, photo5];

  const updateIndex = (newIndex) => {
    if (newIndex < 0 ) newIndex = photos.length - 1;
    else if(newIndex === photos.length) newIndex = 0;

    setActiveIndex(newIndex);
    console.log(photos.length);
};

useEffect(() => {
  const interval = setInterval(() => {
      updateIndex(activeIndex + 1);
  }, 1500);

  return () => {
      if (interval) {
          clearInterval(interval);
      };
  };
});

const styles = { 
  transform: `translateX(-${activeIndex * 100}%)`,
};

  return (
    <div name="Home" className='h-1/2 w-full bg-gradient-to-b from-black via-black to-blue-800'>
        <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center py-24 px-4 md:flex-row md:pt-48'>
            <div className='flex flex-col justify-center items-center h-full text-center md:text-left max-w-md'>
                <h2 className='text-4xl sm:text-6xl font-bold text-white'>Je suis un aspirant <span className='text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500'>Développeur Web</span> </h2>
                <p className='text-gray-500 p-4 font-spacemono md:p-0 md:py-4'>Finissant en techniques d'intégration multimédia et passionné du web, j'adore développer des projets et apprendre de nouvelles technologies</p>

                <div className='pb-4 md:pb-0 md:self-start'>
                <Link to="Portfolio" smooth duration={500}><button className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer'>Mes projets <span className='group-hover:translate-x-1 duration-300'><MdOutlineKeyboardArrowRight size={20} className='ml-1'/></span></button></Link>
                </div>
            </div>
            <div className='overflow-hidden ml-16 bg-gradient-to-l from-cyan-500 to-blue-500 rounded-2xl mx-auto w-3/4'>
              <div className=' whitespace-nowrap transition-transform duration-300 translate-x-100' style={styles}>
                  {photos.map((photo) =>
                    <div className=' inline-flex items-center  '>
                      <img src={photo} alt="Portrait" className=' translate-y-2'/>
                    </div>
                  )}
              </div>
            </div>

        </div>
    </div>
  )
}

export default Home;