import React from 'react';

// Components
import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './components/About';
import SocialLinks from './components/SocialLinks';
import Portfolio from './components/Portfolio';
import Experience from './components/Experience';
import Contact from './components/Contact';

const App = () => {
  return (
    <div className="App">
      <NavBar/>
      <Home/>
      <About/>
      <Portfolio/>
      <Experience/>
      <Contact/>
      
      <SocialLinks/>
    </div>
  );
}

export default App;
