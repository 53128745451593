import React from 'react';

// Assets
import HTML from '../assets/html.png';
import css from '../assets/css.png';
import javascript from '../assets/javascript.png';
import reactImage from '../assets/react.png';
import github from '../assets/github.png';
import tailwind from '../assets/tailwind.png';
import scss from '../assets/scss.png';
import firebase from '../assets/firebase.png';
import c from '../assets/c.png';

const Experience = () => {

  const technologies = [
    {
      id: 1,
      src: HTML,
      title: "Html",
      style: ' shadow-orange-500',
    },
    {
      id: 2,
      src: css,
      title: "Css",
      style: ' shadow-blue-500',
    },
    {
      id: 3,
      src: javascript,
      title: "Java Script",
      style: ' shadow-yellow-500',
    },
    {
      id: 4,
      src: reactImage,
      title: "React",
      style: ' shadow-sky-600',
    },
    {
      id: 5,
      src: github,
      title: "Github",
      style: ' shadow-white',
    },
    {
      id: 6,
      src: tailwind,
      title: "Tailwind",
      style: ' shadow-sky-400',
    },
    {
      id: 7,
      src: scss,
      title: "Scss",
      style: ' shadow-pink-400',
    },
    {
      id: 8,
      src: firebase,
      title: "Firebase",
      style: ' shadow-orange-300',
    },
    {
      id: 9,
      src: c,
      title: "C#",
      style: ' shadow-purple-600',
    }
  ];

  return (
    <div name="Expérience" className='bg-gradient-to-b from-blue-800 to-black w-full h-auto'>
        <div className=' max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white'>
            <div>
              <p className='text-4xl font-bold border-b-4 border-cyan-500 p-2 inline'>Expérience</p>
              <p className='py-6'>Les technologies que j'utilisent</p>
            </div>
            <div className='w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0'>
              {technologies.map(({id, src, title, style}) =>
              <div key={id} className={'shadow-md hover:scale-105 duration-500 py-2 rounded-lg ' + style}>
                <img src={src} alt={title} className='w-20 mx-auto' />
                <p className='mt-4'>{title}</p>
              </div>
              )}
            </div>
        </div>
    </div>
  )
}

export default Experience