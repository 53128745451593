import React from 'react';

const About = () => {
  return (
    <div name='À propos' className='w-full h-auto bg-gradient-to-b from-blue-800 to-black text-white'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-1/2'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-cyan-500'>À propos</p>
            </div>
            <p className='text-xl mt-20 font-spacemono text-gray-400'>
               Sur les écrans depuis tout jeune, j'ai toujour été fasciné par le web. Depuis maintenant 5 ans que je développe des sites web et depuis 3 ans que j'ai décidé d'entreprendre une carrière dans le domaine. 
            </p>

            <br />

            <p className='text-xl font-spacemono text-gray-400'>
              Dans la vie de tous les jours, je suis quelqu'un de passionné, motivé et d'ambitieux. J'adore le plein air et les sports qui font dire "iiiiiiiii Simon tention!" à ma mère.
            </p>
        </div>
    </div>
  )
}

export default About;